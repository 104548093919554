import { Link } from "gatsby"
import React from "react"

import Gif from "../images/404.gif"

export default function Custom404() {
  return (
    <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
      <div>
        <img src={Gif} alt="404" />
      </div>
      <Link to="/">
        <span className="btn btn-dark">Go to Home Page</span>
      </Link>
    </div>
  )
}
